<template>
    <div class="brand">
        <jy-query ref="checkForm" :model="formData">
            <jy-query-item label="所属机构:" prop="orgNa">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="formData.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="线路类型:" prop="routeId">
                <el-select v-model="formData.routeType" placeholder="请选择线路类型">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="线路:" prop="routeId">
                <el-select v-model="formData.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeList" :key="item.routeId" :label="item.cName" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="sDate" label="开始时间">
                <el-date-picker v-model="formData.sDate"   value-format="yyyy-MM-dd" type="date"  placeholder="选择日期"></el-date-picker>
            </jy-query-item>
            <jy-query-item prop="eDate" label="结束时间">
                <el-date-picker v-model="formData.eDate"   value-format="yyyy-MM-dd" type="date"  placeholder="选择日期"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')">重置</el-button>
                <!-- <el-button type="primary" @click="onexport">导出</el-button> -->
                <!-- <el-row>
                    <el-button type="primary" @click="add">新增</el-button>
                    <el-button type="primary" @click="batchdelete">批量删除</el-button>
                    <el-button type="primary" @click="batchimport">导入</el-button>
                </el-row> -->
            </template>
        </jy-query>
        <jy-table :data="lists">
            <jy-table-column type="index" width="60" label="序号"> </jy-table-column>
            <jy-table-column prop="aTime" label="报警时间" min-width="150"> </jy-table-column>
            <jy-table-column prop="aType" label="报警类型" min-width="100"> </jy-table-column>
            <jy-table-column prop="route" label="线路" min-width="100"> </jy-table-column>
            <jy-table-column prop="vNode" label="车牌号码" min-width="150"> </jy-table-column>
            <jy-table-column prop="goType" label="行车方向" min-width="100"> </jy-table-column>
            <jy-table-column prop="speed" label="速度" min-width="100"> </jy-table-column>
            <jy-table-column prop="driver" label="驾驶员" min-width="100"> </jy-table-column>
            <jy-table-column prop="phone" label="联系方式" min-width="120"> </jy-table-column>
            <jy-table-column prop="site" label="报警位置" min-width="150"> </jy-table-column>
            <jy-table-column prop="lng" label="经度" min-width="100"> </jy-table-column>
            <jy-table-column prop="lat" label="纬度" min-width="100"> </jy-table-column>
            <jy-table-column prop="statuName" label="状态" min-width="100"> </jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
        <alarm ref="alarmDetail"></alarm>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree';
import alarm from '@/components/pages/admin/alarm/alarmDetail.vue';
export default {
    data() {
        return {
            schemeId:'',
            formData: {
                orgId: '',
                orgNa: '',
                routeId: '',
                routeType: '',
                sDate: '',
                eDate: ''
            },
            routeList: [],
            lists:[],
            operateList: [],
            // 分页
            pageIndex: 1,
            total: 0,
            pageSize: 10,
            options: [
                    {
                        value: "",
                        label: "全部"
                    },{
                        value: "1",
                        label: "上行"
                    },
                    {
                        value: "2",
                        label: "下形"
                    },
                    {
                        value: "3",
                        label: "环形"
                    }
                ],
        }
    },
    filters: {
        isActive(val) {
            if (val == 0) {
                return "无效";
            } else if (val == 1) {
                return "有效";
            }
        },
        reason(val) {
            let v = "";
            switch (val) {
                case "0":
                    v = "正常";
                    break;
                case "1":
                    v = "砍班";
                    break;
                case "2":
                    v = "脱班";
                    break;
                case "3":
                    v = "调整";
                    break;
            }
            return v;
        },
        type(val) {
            if (val == 1) {
                return "普通班";
            } else if (val == 2) {
                return "加班";
            } else if (val == 3) {
                return "夜班";
            } else {
                return val
            }
        },
        schemeT(val) {
            if (val == 1) {
                return "上行";
            } else if (val == 2) {
                return "下行";
            }
        },
        isCrossL(val) {
            if (val == 1) {
                return "未跨线路";
            } else if (val == 2) {
                return "跨线路";
            }
        },
    },
    components: {
        institutionsTree,
        alarm,
    },
    async created(){
        this.seOperation()
        await this.getRouteList()
        this.getList()
        this.getPlanShiftDetailByPhonet()
    },
    methods:{
        seOperation(){
            this.operateList = [
                {
                    name: '处理',
                    fun: (item) => {
                        this.alarmdel(item)
                    },
                    isShow: (item) => {
                        return item.statu == 1?true:false
                    }
                },
                {
                    name: '查看',
                    fun: (item) => {
                        this.alarmInfo(item)
                    },
                    isShow: (item) => {
                        return item.statu == 2?true:false
                    }
                }
            ]
        },
        async getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            await this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
        getPlanShiftDetailByPhonet() {
			let url = '/base/driver/getPlanShiftDetailByPhone'
			let params = {
				phone: '15263669686'
			}
			this.$http.post(url, params).then(res => {
				console.log(res)
			})
		},
        getList() {
            this.lists = [];
            this.lists.push({
                    aTime: '2021/03/14 15:03',
                    aType: '车辆故障',
                    route: '901',
                    vNode: '闽C81312',
                    goType: '东南',
                    speed: '49Km/H',
                    driver: '张三',
                    phone: '151561151511',
                    site: '闽台博物馆',
                    lng: '45.23',
                    lat: '16.23',
                    statu: '1',
                    statuName: '待处理',
                    detail: '汽车制动失灵，导致无法减速刹车'
            })
            // let option = {
            //     ...this.formData
            // }
            // this.pageIndex = 1
            // let url = '/waybillPlan/queryPage'
            // this.$http.post(url, option).then(({ detail }) => {
            //     this.list = detail
            //     this.total = this.list.length;
            // })
        },
        // 所属机构
        addInstitutions(data) {
            console.log(data)
            this.formData.orgId = data.orgId
            this.formData.orgNa = data.orgNa
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        // 查询表格
        oncheck() {
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.formData.routeType = ''
            this.getDate()
            this.oncheck()
        },
       // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            this.getlist();
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getlist();
        },
        alarmdel(item) {
            this.$refs.alarmDetail.init(item,1)
        },
        alarmInfo(item) {
            this.$refs.alarmDetail.init(item,2)
        }
    },
}
</script>