<template>
    <div class="road_bill_add_update">
        <jy-dialog :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogDoubleWidth">
            <el-form :model="formData" ref="dialogForm" label-width="100px">
                <el-row>
                    <el-col class="title">基本信息</el-col>
                    <el-divider></el-divider>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="报警时间:" prop="aTime">
                            <label>{{formData.aTime}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="驾驶员:" prop="driver">
                            <label>{{formData.driver}}</label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="联系电话:" prop="phone">
                            <label>{{formData.phone}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="报警类型:" prop="aType">
                            <label>{{formData.aType}}</label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col class="title">车辆信息</el-col>
                    <el-divider></el-divider>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="车牌号码:" prop="vNode">
                            <label>{{formData.vNode}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="行车方向:" prop="goType">
                            <label>{{formData.goType}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="行车速度:" prop="speed">
                            <label>{{formData.speed}}</label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="报警位置:" prop="site">
                            <label>{{formData.site}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="经度:" prop="lng">
                            <label>{{formData.lng}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="纬度:" prop="lat">
                            <label>{{formData.lat}}</label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col class="title">详细描述:</el-col>
                </el-row>
                <el-row style="margin: 20px 0"><label>{{formData.detail}}</label></el-row>
                <el-row v-if="isDel == false" style="margin: 20px 0" >
                    <el-col :span="3" class="title">执行命令:</el-col>
                    <el-col :span="20">
                        <el-button>语音下发</el-button>
                        <el-button>轨迹回放</el-button>
                        <el-button>语音对讲</el-button>
                        <el-button>视频监控</el-button>
                    </el-col>
                </el-row>
                <el-row>
                     <el-col :span="3" class="title">处理状态:</el-col>
                     <el-col :span="20">
                         <el-radio-group v-model="formData.statu" v-if="isDel == false">
                            <el-radio v-model="formData.statu" label="1">待处理</el-radio>
                            <el-radio v-model="formData.statu" label="2">已处理</el-radio>
                        </el-radio-group>
                        <label v-else>{{formData.statuName}}</label>
                    </el-col>
                </el-row>
                <el-row>
                     <el-form-item  prop="remark" v-show="formData.statu == '2'">
                        <el-input type="textarea" :rows="3" v-model="formData.remark"></el-input>
                    </el-form-item>

                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save" >确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            dialogVisible: false,
            isDel: false,
            formData: {
                aTime: '',
                aType: '',
                route: '',
                vNode: '',
                goType: '',
                speed: '',
                driver: '',
                phone: '',
                site: '',
                lng: '',
                lat: '',
                statu: '',
                remark: '',
                detail: '',
                statuName: ''
            },
        }
    },
    watch: {
        dialogVisible(val){
            if(!val){
                this.$refs.dialogForm.resetFields()
                this.formData =  {
                    aTime: '',
                    aType: '',
                    route: '',
                    vNode: '',
                    goType: '',
                    speed: '',
                    driver: '',
                    phone: '',
                    site: '',
                    lng: '',
                    lat: '',
                    statu: '',
                    remark: '',
                    detail: ''
                }
            }
        },
    },
    created(){
    },
    methods: {
        init(option, type) {
            if(type == 1) {
                this.isDel = false
            } else {
                this.isDel = true
            }
            if (option) {
                this.formData = {
                    ...this.formData,
                    ...option,
                }
            }
            this.dialogVisible = true
        },
        save() {
            this.dialogVisible = false
        },
    }
}

</script>
<style lang="scss" scoped>
    .title {
        color: #000;
        // font-size: 14px;
        font-weight: bold;
    }
</style>
